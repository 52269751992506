'use client';

import { useState } from 'react';
import { SegmentedControl, Flex, Text, HoverCard, Box, Heading, Skeleton } from '@radix-ui/themes';
import { SlidersHorizontal, ChatCenteredDots, Info, Sparkle, BatteryFull, BatteryHigh, BatteryMedium, BatteryLow, BatteryEmpty } from '@phosphor-icons/react/dist/ssr';
import Link from 'next/link';
import ChatSearch from '~/app/(app)/_modules/new-search-form';
import ManualSearch from '~/app/(app)/_modules/search-form-dialog/search-form-dialog';
import { client } from '~/trpc/client';
const SearchTypeSelector = () => {
  const [value, setValue] = useState<'chat' | 'advanced'>('chat');
  const {
    data,
    isLoading
  } = client.billing.getUsage.useQuery();
  const renderUsageLabel = () => {
    if (!isLoading) {
      const percentageUsed = data?.usage.searches.percentageUsed ?? 0;
      let icon = null;
      let message = `${data?.usage.searches.remaining} of ${data?.usage.searches.limit} remaining`;
      let colorClass = 'text-black';
      if (percentageUsed === 100) {
        icon = <BatteryEmpty size={16} weight="duotone" className="text-rose-600" />;
        message = 'You\'ve used your monthly search quota';
        colorClass = 'text-rose-600';
      } else if (percentageUsed >= 80) {
        icon = <BatteryLow size={16} />;
        colorClass = 'text-rose-600';
      } else if (percentageUsed >= 50) {
        icon = <BatteryMedium size={16} />;
        colorClass = 'text-amber-600';
      } else if (percentageUsed >= 20) {
        icon = <BatteryHigh size={16} />;
        colorClass = 'text-emerald-600';
      } else if (percentageUsed > 0) {
        icon = <BatteryFull size={16} />;
        colorClass = 'text-emerald-600';
      } else {
        icon = <BatteryFull size={16} />;
        colorClass = 'text-emerald-600';
      }
      return <div className={`flex items-center gap-1 ${colorClass}`}>
					{icon}
					<span className="flex items-center gap-1">
						{message}
						{data?.plan === 'free' && percentageUsed > 50 && <Link href="/billing">
								<span className="font-semibold text-black">
									{'– Upgrade'}
								</span>
							</Link>}
					</span>
				</div>;
    }
    return <Skeleton className="w-[300px]" data-sentry-element="Skeleton" data-sentry-component="renderUsageLabel" data-sentry-source-file="search-type-selector.tsx" />;
  };
  return <div data-sentry-component="SearchTypeSelector" data-sentry-source-file="search-type-selector.tsx">
			<Flex align="center" justify="between" direction="row" gap="2" mb="2" data-sentry-element="Flex" data-sentry-source-file="search-type-selector.tsx">
				<Flex align="center" gap="1" data-sentry-element="Flex" data-sentry-source-file="search-type-selector.tsx">
					<Sparkle className="text-violet-600" weight="duotone" data-sentry-element="Sparkle" data-sentry-source-file="search-type-selector.tsx" />
					<Text weight="medium" data-sentry-element="Text" data-sentry-source-file="search-type-selector.tsx">
						{'Describe who you\'re looking for'}
					</Text>
				</Flex>
				<Flex align="center" justify="start" gap="2" data-sentry-element="Flex" data-sentry-source-file="search-type-selector.tsx">
					<HoverCard.Root data-sentry-element="unknown" data-sentry-source-file="search-type-selector.tsx">
						<HoverCard.Trigger data-sentry-element="unknown" data-sentry-source-file="search-type-selector.tsx">
							<Info data-sentry-element="Info" data-sentry-source-file="search-type-selector.tsx" />
						</HoverCard.Trigger>
						<HoverCard.Content maxWidth="300px" data-sentry-element="unknown" data-sentry-source-file="search-type-selector.tsx">
							<Flex gap="4" data-sentry-element="Flex" data-sentry-source-file="search-type-selector.tsx">
								<Box data-sentry-element="Box" data-sentry-source-file="search-type-selector.tsx">
									<Heading size="3" as="h3" mb="2" data-sentry-element="Heading" data-sentry-source-file="search-type-selector.tsx">
										{'Choose a search method'}
									</Heading>
									<Text as="div" size="2" mb="4" data-sentry-element="Text" data-sentry-source-file="search-type-selector.tsx">
										<span className="font-semibold">
											{'Chat'}
										</span>
										{' with Dunbar to build your search naturally. Just describe who you are looking for, and Dunbar will help find the right matches through conversation.'}
									</Text>
									<Text as="div" size="2" mb="4" data-sentry-element="Text" data-sentry-source-file="search-type-selector.tsx">
										<span className="font-semibold">
											{'Advanced'}
										</span>
										{' search lets you control your search filters. Dunbar will still help optimize your query, but you can review and adjust the filters before submitting.'}
									</Text>
								</Box>
							</Flex>
						</HoverCard.Content>
					</HoverCard.Root>
					<SegmentedControl.Root defaultValue="chat" variant="classic" onValueChange={(value: 'chat' | 'advanced') => {
          setValue(value);
        }} data-sentry-element="unknown" data-sentry-source-file="search-type-selector.tsx">
						<SegmentedControl.Item value="chat" data-sentry-element="unknown" data-sentry-source-file="search-type-selector.tsx">
							<Flex align="center" gap="2" data-sentry-element="Flex" data-sentry-source-file="search-type-selector.tsx">
								<ChatCenteredDots data-sentry-element="ChatCenteredDots" data-sentry-source-file="search-type-selector.tsx" />
								{'Chat'}
							</Flex>
						</SegmentedControl.Item>
						<SegmentedControl.Item value="advanced" data-sentry-element="unknown" data-sentry-source-file="search-type-selector.tsx">
							<Flex align="center" gap="2" data-sentry-element="Flex" data-sentry-source-file="search-type-selector.tsx">
								<SlidersHorizontal data-sentry-element="SlidersHorizontal" data-sentry-source-file="search-type-selector.tsx" />
								{'Advanced'}
							</Flex>
						</SegmentedControl.Item>

					</SegmentedControl.Root>
				</Flex>
			</Flex>
			{value === 'chat' && <ChatSearch searchLimitReached={data?.usage.searches.percentageUsed === 100} placeholder="Machine learning engineers in San Francisco" />}
			{value === 'advanced' && <ManualSearch searchLimitReached={data?.usage.searches.percentageUsed === 100} />}
			<div className="px py-2">
				<div className="flex items-center gap-1 text-sm">
					{renderUsageLabel()}
				</div>
			</div>
		</div>;
};
export default SearchTypeSelector;