'use client';

import { Button, TextField } from '@radix-ui/themes';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';
import { useRef, useActionState } from 'react';
import { useForm } from '@conform-to/react';
import { startConversation } from '../actions';
import { CircleNotch } from '@phosphor-icons/react';
const SearchForm = ({
  placeholder,
  searchLimitReached
}: Readonly<{
  placeholder: string;
  searchLimitReached: boolean;
}>) => {
  const [lastResult, action, isPending] = useActionState(startConversation, undefined);
  const [form, fields] = useForm({
    lastResult,
    onValidate: ({
      formData
    }) => {
      return parseWithZod(formData, {
        schema: z.object({
          query: z.string()
        })
      });
    }
  });
  const submitRef = useRef<HTMLButtonElement>(null);
  return <div className="peer flex rounded-lg border border-neutral-200 bg-neutral-100 p-1 has-[:focus]:border-neutral-300" data-sentry-component="SearchForm" data-sentry-source-file="new-search-form.tsx">
			<form id={form.id} className="flex w-full items-center justify-between" action={action} onSubmit={form.onSubmit} noValidate={true}>
				<TextField.Root id={fields.query.id} name={fields.query.name} placeholder={placeholder} variant="soft" className="w-full bg-transparent text-black caret-dunbar-purple outline-none" size="3" autoFocus={true} autoComplete="off" spellCheck={false} onKeyDown={event => {
        if (event.key === 'Enter') {
          if (event.shiftKey) {
            event.preventDefault();
          }
          if (!event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
            event.preventDefault();
            submitRef.current?.click();
          }
        }
      }} disabled={isPending} data-sentry-element="unknown" data-sentry-source-file="new-search-form.tsx" />
				<Button className="w-fit" type="submit" ref={submitRef} disabled={isPending || !fields.query.value || searchLimitReached} variant="classic" size="3" data-sentry-element="Button" data-sentry-source-file="new-search-form.tsx">
					{isPending ? <CircleNotch size={16} className="animate-spin" role="status" aria-label="Loading lists" /> : null}
					{'Search'}
				</Button>
			</form>
		</div>;
};
export default SearchForm;