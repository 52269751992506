import { useState } from 'react';
import { Dialog, Button, IconButton, Text, TextField, Flex, ScrollArea, Badge, Card, Spinner, DropdownMenu } from '@radix-ui/themes';
import { PencilLine, ArrowLeft, X, Plus } from '@phosphor-icons/react/dist/ssr';
import { redirect } from 'next/navigation';
import { client } from '~/trpc/client';
import { usePostHog } from 'posthog-js/react';
type EditableFilters = Record<'companies' | 'titles' | 'locations' | 'skills' | 'languages' | 'educations', {
  base: string | null;
  expanded: string[] | null;
}>;
const EntityFilter = ({
  filter,
  filterKey,
  onEdit,
  onRemove
}: Readonly<{
  filter: {
    base: string | null;
    expanded: string[] | null;
  };
  filterKey: keyof EditableFilters;
  onEdit: (filterKey: keyof EditableFilters) => void;
  onRemove: (filterKey: keyof EditableFilters) => void;
}>) => {
  const [showAllTerms, setShowAllTerms] = useState(false);
  const MAX_VISIBLE_TERMS = 3;
  const visibleTerms = filter.expanded?.slice(0, showAllTerms ? undefined : MAX_VISIBLE_TERMS) ?? [];
  const hiddenTermsCount = (filter.expanded?.length ?? 0) - MAX_VISIBLE_TERMS;
  const isNew = filter.base === '';
  return <Card data-sentry-element="Card" data-sentry-component="EntityFilter" data-sentry-source-file="review-step.tsx">
			<Flex justify="between" align="center" mb="2" data-sentry-element="Flex" data-sentry-source-file="review-step.tsx">
				<Text weight="medium" size="4" className="capitalize" data-sentry-element="Text" data-sentry-source-file="review-step.tsx">
					{filterKey}
				</Text>
				<Flex align="center" gap="4" data-sentry-element="Flex" data-sentry-source-file="review-step.tsx">
					<Button onClick={() => {
          onEdit(filterKey);
        }} variant="ghost" data-sentry-element="Button" data-sentry-source-file="review-step.tsx">
						<PencilLine data-sentry-element="PencilLine" data-sentry-source-file="review-step.tsx" />
						{'Edit'}
					</Button>
					<Button onClick={() => {
          onRemove(filterKey);
        }} variant="ghost" data-sentry-element="Button" data-sentry-source-file="review-step.tsx">
						<X data-sentry-element="X" data-sentry-source-file="review-step.tsx" />
						{'Delete'}
					</Button>
				</Flex>

			</Flex>
			<Badge className={isNew ? 'bg-rose-200 text-rose-600' : ''} size="3" radius="full" data-sentry-element="Badge" data-sentry-source-file="review-step.tsx">
				{isNew ? 'No values set' : filter.base}
			</Badge>
			{filter.expanded && filter.expanded.length > 0 ? <div className="px-4 py-2">
					<div className="flex flex-col border-l-2 border-dunbar-purple-600 px-4">
						<Text className="text-neutral-600">
							{'Including similar matches:'}
						</Text>
						<Flex wrap="wrap" gap="2" my="2">
							{visibleTerms.map(item => {
            return <Badge key={item} variant="soft" radius="full" color="gray" size="2">
										{item}
									</Badge>;
          })}
							{!showAllTerms && hiddenTermsCount > 0 && <Badge variant="outline" radius="full" color="gray" size="2" className="cursor-pointer" onClick={() => {
            setShowAllTerms(true);
          }}>
									{`+ ${hiddenTermsCount} more`}
								</Badge>}
						</Flex>
					</div>
				</div> : null}
		</Card>;
};
const ReviewStep = ({
  query,
  filters,
  resetSearch
}: Readonly<{
  query: string;
  filters: EditableFilters;
  resetSearch: () => void;
}>) => {
  const posthog = usePostHog();
  const [editingFilterKey, setEditingFilterKey] = useState<keyof EditableFilters | null>(null);
  const [editedFilters, setEditedFilters] = useState<EditableFilters>(filters);
  const [creatingSearch, setCreatingSearch] = useState(false);
  const {
    mutateAsync: createQueryFromFilters
  } = client.search.createQueryFromFilters.useMutation();
  const {
    mutateAsync: createSearchConversation
  } = client.conversations.createSearchConversation.useMutation();
  const handleCreateSearch = async () => {
    posthog.capture('search_conversation:use_filtered_search');
    let conversationId: number | null = null;
    try {
      setCreatingSearch(true);
      const {
        formattedQuery
      } = await createQueryFromFilters({
        filters: JSON.stringify(editedFilters)
      });
      conversationId = await createSearchConversation({
        initialMessage: formattedQuery
      });
    } catch {
      setCreatingSearch(false);
      return;
    }
    redirect(`/search/${conversationId}`);
  };
  const removeFilterKey = (key: keyof EditableFilters) => {
    setEditedFilters(prev => {
      const newFilters = {
        ...prev
      };
      newFilters[key] = {
        base: null,
        expanded: null
      };
      return newFilters;
    });
  };
  const handleUpdateBase = (value: string) => {
    if (!editingFilterKey) {
      return;
    }
    setEditedFilters(prev => {
      return {
        ...prev,
        [editingFilterKey]: {
          ...prev[editingFilterKey],
          base: value
        }
      };
    });
  };
  const handleUpdateExpanded = (index: number, value: string) => {
    if (!editingFilterKey) {
      return;
    }
    setEditedFilters(prev => {
      const expanded = [...(prev[editingFilterKey].expanded ?? [])];
      expanded[index] = value;
      return {
        ...prev,
        [editingFilterKey]: {
          ...prev[editingFilterKey],
          expanded
        }
      };
    });
  };
  const handleAddExpanded = () => {
    if (!editingFilterKey) {
      return;
    }
    setEditedFilters(prev => {
      return {
        ...prev,
        [editingFilterKey]: {
          ...prev[editingFilterKey],
          expanded: [...(prev[editingFilterKey].expanded ?? []), '']
        }
      };
    });
  };
  const handleRemoveExpanded = (index: number) => {
    if (!editingFilterKey) {
      return;
    }
    setEditedFilters(prev => {
      const expanded = [...(prev[editingFilterKey].expanded ?? [])];
      expanded.splice(index, 1);
      return {
        ...prev,
        [editingFilterKey]: {
          ...prev[editingFilterKey],
          expanded
        }
      };
    });
  };
  return <div data-sentry-component="ReviewStep" data-sentry-source-file="review-step.tsx">
			{editingFilterKey === null && <>
					<Dialog.Title mb="1">
						{'Suggested filters'}
					</Dialog.Title>
					<Dialog.Description size="3" mb="4">
						{`Based on your query for "${query}", we suggest:`}
					</Dialog.Description>
					<ScrollArea type="auto" scrollbars="vertical" style={{
        maxHeight: 600
      }}>
						<Flex direction="column" gap="2">
							{Object.keys(editedFilters).filter(filterKey => {
            return editedFilters[filterKey as keyof EditableFilters].base !== null;
          }).map(filterKey => {
            return <EntityFilter key={filterKey} filterKey={filterKey as keyof EditableFilters} filter={editedFilters[filterKey as keyof EditableFilters]} onEdit={setEditingFilterKey} onRemove={removeFilterKey} />;
          })}
						</Flex>
					</ScrollArea>
					<Flex gap="3" mt="4" justify="between">

						<div>
							<DropdownMenu.Root>
								<DropdownMenu.Trigger>
									<Button variant="soft">
										<Plus />
										{'Add filter'}
										<DropdownMenu.TriggerIcon />
									</Button>
								</DropdownMenu.Trigger>
								<DropdownMenu.Content>
									<DropdownMenu.Item disabled={editedFilters.titles.base !== null} onSelect={() => {
                setEditedFilters({
                  ...editedFilters,
                  titles: {
                    base: '',
                    expanded: []
                  }
                });
              }}>
										{'Title'}
									</DropdownMenu.Item>
									<DropdownMenu.Item disabled={editedFilters.companies.base !== null} onSelect={() => {
                setEditedFilters({
                  ...editedFilters,
                  companies: {
                    base: '',
                    expanded: []
                  }
                });
              }}>
										{'Company'}
									</DropdownMenu.Item>
									<DropdownMenu.Item disabled={editedFilters.locations.base !== null} onSelect={() => {
                setEditedFilters({
                  ...editedFilters,
                  locations: {
                    base: '',
                    expanded: []
                  }
                });
              }}>
										{'Location'}
									</DropdownMenu.Item>
									<DropdownMenu.Item disabled={editedFilters.skills.base !== null} onSelect={() => {
                setEditedFilters({
                  ...editedFilters,
                  skills: {
                    base: '',
                    expanded: []
                  }
                });
              }}>
										{'Skill'}
									</DropdownMenu.Item>
									<DropdownMenu.Item disabled={editedFilters.educations.base !== null} onSelect={() => {
                setEditedFilters({
                  ...editedFilters,
                  educations: {
                    base: '',
                    expanded: []
                  }
                });
              }}>
										{'Education'}
									</DropdownMenu.Item>
									<DropdownMenu.Item disabled={editedFilters.languages.base !== null} onSelect={() => {
                setEditedFilters({
                  ...editedFilters,
                  languages: {
                    base: '',
                    expanded: []
                  }
                });
              }}>
										{'Languages'}
									</DropdownMenu.Item>
								</DropdownMenu.Content>
							</DropdownMenu.Root>
						</div>
						<Flex align="center" gap="3">
							<Dialog.Close>
								<Button onClick={resetSearch} variant="soft" color="gray">
									{'Cancel'}
								</Button>
							</Dialog.Close>
							<Button disabled={creatingSearch} onClick={() => {
            void handleCreateSearch();
          }} variant="classic">
								{creatingSearch ? <Spinner /> : null}
								{'Run Search'}
							</Button>
						</Flex>

					</Flex>
				</>}
			{editingFilterKey !== null && <div>
					<Dialog.Title mb="1" className="flex items-center justify-center capitalize">
						{`Edit ${editingFilterKey}`}
					</Dialog.Title>
					<Dialog.Description size="3" mb="4" mt="2" className="flex items-center gap-2">
						<Button variant="ghost" size="3" onClick={() => {
          setEditingFilterKey(null);
        }}>
							<ArrowLeft />
							{'Back to filters'}
						</Button>
					</Dialog.Description>
					<Flex direction="column" gap="2">

						<label>
							<Text size="3" weight="medium">
								{'Primary'}
							</Text>
							<div className="py-2">
								<TextField.Root variant="classic" size="3" value={String(editedFilters[editingFilterKey].base)} onChange={e => {
              handleUpdateBase(e.target.value);
            }} />
							</div>
						</label>
						{(editedFilters[editingFilterKey].expanded ?? []).length > 0 ? <>
								<Text size="3" weight="medium">
									{'Similar matches'}
								</Text>
								<Flex gap="2" direction="column">
									{editedFilters[editingFilterKey].expanded?.map((item, index) => {
              return <Flex key={index} align="center" justify="between" gap="4">
												<TextField.Root variant="classic" size="3" value={String(item)} className="w-full" onChange={e => {
                  handleUpdateExpanded(index, e.target.value);
                }} />
												<IconButton size="3" variant="ghost" color="gray" onClick={() => {
                  handleRemoveExpanded(index);
                }}>
													<X size={20} />
												</IconButton>
											</Flex>;
            })}
									<div className="pt-4">
										<Button size="3" variant="ghost" onClick={handleAddExpanded}>
											<Plus />
											{'Add another match'}
										</Button>
									</div>
								</Flex>
							</> : <div className="pt-4">
								<Button size="3" variant="ghost" onClick={handleAddExpanded}>
									<Plus />
									{'Add another match'}
								</Button>
							</div>}
					</Flex>
					<Flex justify="end" className="mt-4 border-t pt-4">
						<Button size="3" onClick={() => {
          setEditingFilterKey(null);
        }}>
							{'Save changes'}
						</Button>
					</Flex>
				</div>}
		</div>;
};
export default ReviewStep;