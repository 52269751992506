'use client';

import { client } from '~/trpc/client';
import { useRouter } from 'next/navigation';
import { CircleNotch, X, Check } from '@phosphor-icons/react';
import { cn } from '~/utils/cn';
const HideButton = ({
  conversationId
}: Readonly<{
  conversationId: number;
}>) => {
  const {
    mutateAsync: hideConversation,
    isPending,
    isSuccess
  } = client.conversations.hideConversation.useMutation();
  const {
    refresh
  } = useRouter();
  return <div title="Hide" className={cn('pointer-events-none absolute right-0 top-0 z-10 -m-xs -translate-y-1/4 translate-x-1/4 p-xs', isPending || isSuccess ? 'pointer-events-auto' : 'hover:pointer-events-auto group-hover:pointer-events-auto')} data-sentry-component="HideButton" data-sentry-source-file="hide-button.tsx">
			<button disabled={isPending || isSuccess} className={cn('rounded-full bg-white/60 p-xs text-neutral-800 opacity-0 shadow backdrop-blur transition-opacity delay-0 duration-0', isPending || isSuccess ? 'opacity-100 delay-[1500ms]' : 'hover:opacity-100 hover:delay-[1500ms] group-hover:opacity-100 group-hover:delay-[1500ms]')} onClick={async event => {
      event.stopPropagation();
      await hideConversation({
        conversationId
      });
      refresh();
    }}>
				{isPending ? <CircleNotch size={14} weight="bold" className="animate-spin" /> : isSuccess ? <Check size={14} weight="bold" /> : <X size={14} weight="bold" />}
			</button>
		</div>;
};
export default HideButton;