'use client';

import { useState } from 'react';
import { Dialog } from '@radix-ui/themes';
import QueryStep from './query-step';
import AnalysisStep from './analysis-step';
import ReviewStep from './review-step';
import { client } from '~/trpc/client';
enum AnalysisState {
  ENTITY_EXTRACTION = 'entity_extraction',
  ENTITY_EXPANSION = 'entity_expansion',
  ENTITY_EXPANSION_WITH_WEB = 'entity_expansion_with_web',
  BUILD_FILTERS = 'build_filters',
  DONE = 'done',
}
const SearchFormDialog = ({
  searchLimitReached
}: Readonly<{
  searchLimitReached: boolean;
}>) => {
  const [step, setStep] = useState<'query' | 'analysis' | 'review'>('query');
  const [searchQuery, setSearchQuery] = useState('');
  const [analysisState, setAnalysisState] = useState<AnalysisState>(AnalysisState.ENTITY_EXTRACTION);
  const [openDialoge, setOpenDialoge] = useState(false);
  const [entityFilters, setEntityFilters] = useState<Record<'companies' | 'titles' | 'locations' | 'skills' | 'languages' | 'educations', {
    base: string | null;
    expanded: string[] | null;
  }>>({
    companies: {
      base: null,
      expanded: []
    },
    titles: {
      base: null,
      expanded: []
    },
    locations: {
      base: null,
      expanded: []
    },
    skills: {
      base: null,
      expanded: []
    },
    languages: {
      base: null,
      expanded: []
    },
    educations: {
      base: null,
      expanded: []
    }
  });
  const {
    mutateAsync: createEntityFilters
  } = client.search.createEntityFilters.useMutation();
  const {
    mutateAsync: createExpansionFilters
  } = client.search.createExpansionFilters.useMutation();
  const {
    mutateAsync: getCompanyWebSearchQuery
  } = client.search.getCompanyWebSearchQuery.useMutation();
  const handleBuildFilters = async (query: string) => {
    const {
      filters: entityFilters
    } = await createEntityFilters({
      query
    });
    setAnalysisState(AnalysisState.ENTITY_EXPANSION);

    // Check if we need to run a perplexity company search.
    // The separation of this call is to be able to show state in the
    // UI that this might take a min.

    const companyWebSearchQuery = await getCompanyWebSearchQuery({
      query
    });
    if (companyWebSearchQuery) {
      setAnalysisState(AnalysisState.ENTITY_EXPANSION_WITH_WEB);
    }
    const {
      filters: expansionFilters
    } = await createExpansionFilters({
      companyWebSearchQuery,
      companies: entityFilters.companies,
      titles: entityFilters.titles,
      locations: entityFilters.locations,
      skills: entityFilters.skills,
      languages: entityFilters.languages,
      educations: entityFilters.educations
    });
    setAnalysisState(AnalysisState.BUILD_FILTERS);
    setEntityFilters({
      companies: {
        base: entityFilters.companies,
        expanded: expansionFilters.companies
      },
      titles: {
        base: entityFilters.titles,
        expanded: expansionFilters.titles
      },
      locations: {
        base: entityFilters.locations,
        expanded: expansionFilters.locations
      },
      skills: {
        base: entityFilters.skills,
        expanded: expansionFilters.skills
      },
      languages: {
        base: entityFilters.languages,
        expanded: expansionFilters.languages
      },
      educations: {
        base: entityFilters.educations,
        expanded: expansionFilters.educations
      }
    });
    setTimeout(() => {
      setAnalysisState(AnalysisState.DONE);
    }, 2000);
    setTimeout(() => {
      setStep('review');
    }, 1000);
  };
  const resetSearch = () => {
    setOpenDialoge(false);
    setSearchQuery('');
    setStep('query');
    setEntityFilters({
      companies: {
        base: null,
        expanded: []
      },
      titles: {
        base: null,
        expanded: []
      },
      locations: {
        base: null,
        expanded: []
      },
      skills: {
        base: null,
        expanded: []
      },
      languages: {
        base: null,
        expanded: []
      },
      educations: {
        base: null,
        expanded: []
      }
    });
    setAnalysisState(AnalysisState.ENTITY_EXTRACTION);
  };
  return <div data-sentry-component="SearchFormDialog" data-sentry-source-file="search-form-dialog.tsx">
			<QueryStep searchLimitReached={searchLimitReached} onNextStep={(query: string) => {
      setSearchQuery(query);
      setStep('analysis');
      setOpenDialoge(true);
      void handleBuildFilters(query);
    }} data-sentry-element="QueryStep" data-sentry-source-file="search-form-dialog.tsx" />
			<Dialog.Root open={openDialoge} data-sentry-element="unknown" data-sentry-source-file="search-form-dialog.tsx">
				<Dialog.Content className="max-h-[85vh] w-full overflow-y-auto" maxWidth="700px" size="4" data-sentry-element="unknown" data-sentry-source-file="search-form-dialog.tsx">

					{step === 'analysis' && <AnalysisStep searchQuery={searchQuery} analysisState={analysisState} />}
					{step === 'review' && <ReviewStep query={searchQuery} filters={entityFilters} resetSearch={resetSearch} />}
				</Dialog.Content>
			</Dialog.Root>
		</div>;
};
export default SearchFormDialog;