import { Dialog, Text, Flex, Spinner } from '@radix-ui/themes';
import { Check, Sparkle, Globe } from '@phosphor-icons/react/dist/ssr';
import { TextShimmerWave } from '~/components/shadcn/text-shimmer-wave';
enum StepStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}
enum AnalysisState {
  ENTITY_EXTRACTION = 'entity_extraction',
  ENTITY_EXPANSION = 'entity_expansion',
  ENTITY_EXPANSION_WITH_WEB = 'entity_expansion_with_web',
  BUILD_FILTERS = 'build_filters',
  DONE = 'done',
}
const ANALYSIS_STEPS = {
  analyze: {
    key: 'analyze',
    label: 'Analyzing search intent',
    analysisState: AnalysisState.ENTITY_EXTRACTION
  },
  expand: {
    key: 'expand',
    label: 'Expanding search terms',
    analysisState: AnalysisState.ENTITY_EXPANSION
  },
  build: {
    key: 'build',
    label: 'Building recommended filters',
    analysisState: AnalysisState.BUILD_FILTERS
  }
} as const;
const STEP_ORDER = ['analyze', 'expand', 'build'] as const;
const AnalysisStepItem = ({
  label,
  status,
  state,
  stepKey
}: Readonly<{
  label: string;
  status: StepStatus;
  state: AnalysisState;
  stepKey: keyof typeof ANALYSIS_STEPS;
}>) => {
  return <Flex direction="column" justify="center" align="center" gap="2" data-sentry-element="Flex" data-sentry-component="AnalysisStepItem" data-sentry-source-file="analysis-step.tsx">

			<Flex align="center" justify="center" gap="2" data-sentry-element="Flex" data-sentry-source-file="analysis-step.tsx">
				{status === StepStatus.IN_PROGRESS ? <Spinner /> : <Check className={`size-4 ${status === StepStatus.COMPLETED ? 'text-emerald-400' : 'text-neutral-400'}`} />}
				{status === StepStatus.IN_PROGRESS ? <TextShimmerWave duration={0.5} className="text-lg">
						{label}
					</TextShimmerWave> : <Text size="2" className={`text-lg ${status === StepStatus.COMPLETED ? 'text-black' : 'text-neutral-400'}`}>
						{label}
					</Text>}
			</Flex>
			{stepKey === 'expand' && state === AnalysisState.ENTITY_EXPANSION_WITH_WEB && <Flex align="center" justify="center" gap="2">
					<Globe size={16} />
					<Text size="2">
						{'Using web search to expand company list. This can take a minute.'}
					</Text>
				</Flex>}

		</Flex>;
};
const renderStepState = (step: keyof typeof ANALYSIS_STEPS, currentState: AnalysisState): StepStatus => {
  // If we're done, all steps should be completed
  if (currentState === AnalysisState.DONE) {
    return StepStatus.COMPLETED;
  }
  const currentStepIndex = STEP_ORDER.indexOf(step);
  const activeStepIndex = STEP_ORDER.findIndex(stepKey => {
    // need to hijack the entity expansion with web step
    // if state === ENTITY_EXPANSION_WITH_WEB, then
    // we still want to show the spinner for the entity expansion step

    if (stepKey === 'expand' && currentState === AnalysisState.ENTITY_EXPANSION_WITH_WEB) {
      return true;
    }
    return ANALYSIS_STEPS[stepKey].analysisState === currentState;
  });
  if (currentStepIndex < activeStepIndex) {
    return StepStatus.COMPLETED;
  }
  if (currentStepIndex === activeStepIndex) {
    return StepStatus.IN_PROGRESS;
  }
  return StepStatus.PENDING;
};
const AnalysisStep = ({
  analysisState,
  searchQuery
}: Readonly<{
  searchQuery: string;
  analysisState: AnalysisState;
}>) => {
  return <div data-sentry-component="AnalysisStep" data-sentry-source-file="analysis-step.tsx">
			<Flex direction="column" gap="2" justify="center" align="center" data-sentry-element="Flex" data-sentry-source-file="analysis-step.tsx">
				<Sparkle className="size-8 text-dunbar-purple" data-sentry-element="Sparkle" data-sentry-source-file="analysis-step.tsx" />
				<Dialog.Title mb="1" data-sentry-element="unknown" data-sentry-source-file="analysis-step.tsx">
					{'Building filters...'}
				</Dialog.Title>
				<Dialog.Description size="2" mb="4" className="mx-auto max-w-md text-center text-neutral-500" data-sentry-element="unknown" data-sentry-source-file="analysis-step.tsx">
					{`We're analyzing your search for "${searchQuery}" to create relevant filters and suggestions`}
				</Dialog.Description>
				<Flex direction="column" gap="2" justify="center" align="center" data-sentry-element="Flex" data-sentry-source-file="analysis-step.tsx">
					{STEP_ORDER.map(stepKey => {
          return <AnalysisStepItem key={stepKey} label={ANALYSIS_STEPS[stepKey].label} state={analysisState} status={renderStepState(stepKey, analysisState)} stepKey={stepKey} />;
        })}
				</Flex>
			</Flex>
		</div>;
};
export default AnalysisStep;