import { useState, useRef } from 'react';
import { TextField, Button } from '@radix-ui/themes';
const QueryStep = ({
  searchLimitReached,
  onNextStep
}: Readonly<{
  searchLimitReached: boolean;
  onNextStep: (term: string) => void;
}>) => {
  const [searchTerm, setSearchTerm] = useState('');
  const submitRef = useRef<HTMLButtonElement>(null);
  return <div className="peer flex rounded-lg border border-neutral-200 bg-neutral-100 p-1 has-[:focus]:border-neutral-300" data-sentry-component="QueryStep" data-sentry-source-file="query-step.tsx">
			<div className="flex w-full items-center justify-between">
				<TextField.Root variant="soft" className="w-full bg-transparent text-black caret-dunbar-purple outline-none" size="3" autoFocus={true} spellCheck={false} autoComplete="off" value={searchTerm} placeholder="Machine learning engineers in San Francisco" onChange={e => {
        setSearchTerm(e.target.value);
      }} onKeyDown={event => {
        if (event.key === 'Enter') {
          if (event.shiftKey) {
            event.preventDefault();
          }
          if (!event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
            event.preventDefault();
            submitRef.current?.click();
          }
        }
      }} data-sentry-element="unknown" data-sentry-source-file="query-step.tsx" />
				<Button className="w-fit" ref={submitRef} disabled={searchTerm.trim() === '' || searchLimitReached} onClick={() => {
        onNextStep(searchTerm);
      }} variant="classic" size="3" data-sentry-element="Button" data-sentry-source-file="query-step.tsx">
					{'Search'}
				</Button>
			</div>
		</div>;
};
export default QueryStep;