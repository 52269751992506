'use client';

import NumberFlow from '@number-flow/react';
import { useState, type ComponentProps } from 'react';
import { useTimeout } from 'usehooks-ts';
import { useNonce } from '~/app/_providers/nonce-provider';
const CountUpFlow = (props: Readonly<{
  to: number;
  from?: number;
} & Omit<ComponentProps<typeof NumberFlow>, 'value'>>) => {
  const nonce = useNonce();
  const {
    to,
    from = 0,
    ...rest
  } = props;
  const [value, setValue] = useState(from);
  useTimeout(() => {
    setValue(to);
  }, 0);
  return <NumberFlow {...rest} value={value} nonce={nonce} data-sentry-element="NumberFlow" data-sentry-component="CountUpFlow" data-sentry-source-file="count-up-flow.tsx" />;
};
export default CountUpFlow;