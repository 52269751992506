'use client';

import { motion } from 'motion/react';
import CountUpFlow from '~/components/count-up-flow';
import { Text } from '@radix-ui/themes';
const NetworkCount = ({
  totalNetworkSize
}: Readonly<{
  totalNetworkSize: number;
}>) => {
  return <Text className="flex items-center justify-center space-x-1.5" size="7" weight="medium" data-sentry-element="Text" data-sentry-component="NetworkCount" data-sentry-source-file="network-count.tsx">
			<motion.span layout="position" transition={{
      type: 'spring',
      stiffness: 500,
      damping: 30
    }} data-sentry-element="unknown" data-sentry-source-file="network-count.tsx">
				{'Search across '}
			</motion.span>
			<motion.span layout={true} transition={{
      type: 'spring',
      stiffness: 500,
      damping: 30
    }} className="font-semibold" data-sentry-element="unknown" data-sentry-source-file="network-count.tsx">
				<CountUpFlow to={totalNetworkSize} trend={0} data-sentry-element="CountUpFlow" data-sentry-source-file="network-count.tsx" />
			</motion.span>
			<motion.span layout="position" transition={{
      type: 'spring',
      stiffness: 500,
      damping: 30
    }} data-sentry-element="unknown" data-sentry-source-file="network-count.tsx">
				{' people in your network.'}
			</motion.span>
		</Text>;
};
export default NetworkCount;